import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';
import Post from '../components/Post';
import PostEvent from '../components/PostEvent';
import { mixedListOfArticlesAndEvents } from '../utils/common';

const TOTags = ({ data }) => {
  const allPostsSorted = mixedListOfArticlesAndEvents(
    data.allDatoCmsBeitragBvogOfakt.edges,
    data.allDatoCmsVeranstaltungen.edges
  );

  return (
    <Layout
      title={
        data.datoCmsOsterreichbilanzen.seo &&
        `${data.datoCmsOsterreichbilanzen.seo.title} – Österreich-Bilanz`
      }
      description={
        data.datoCmsOsterreichbilanzen.seo && data.datoCmsOsterreichbilanzen.seo.description
      }
    >
      <div className="common-page article-page">
        <Subheader title="ÖSTERREICH-BILANZ" intro={data.datoCmsOsterreichbilanzen.fixtext} />

        <div className="container">
          <div className="er-template">
            <div className="article-feed">
              {allPostsSorted.map(({ node }) =>
                node.model.apiKey === 'veranstaltungen' ? (
                  <PostEvent key={node.id} node={node} />
                ) : (
                  <Post key={node.id} node={node} page="Osterreichbilanz" />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TOTags;

export const query = graphql`
  query($hashtag: String!) {
    datoCmsOsterreichbilanzen(hashtag: { eq: $hashtag }) {
      fixtext
      hashtag
      seo {
        title
        description
      }
    }

    allDatoCmsBeitragBvogOfakt(
      filter: { bvog: { eq: true }, oesterreichbilanz: { elemMatch: { link: { eq: $hashtag } } } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          ...DatoBeitragFields
          meta {
            createdAt
          }
          model {
            apiKey
          }
        }
      }
    }

    allDatoCmsVeranstaltungen(
      filter: { oesterreichbilanz: { elemMatch: { link: { eq: $hashtag } } } }
      sort: { fields: [beginn], order: DESC }
    ) {
      edges {
        node {
          ...DatoVeranstaltungenFields

          meta {
            createdAt
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`;
